// IMPORT REACT
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

// IMPORT API & APOLLO
import { useQuery } from '../../hooks/useQuery';
import { GET_PROGRAMMES_QUERY, GET_SEANCES_QUERY, GET_FILTERS_QUERY } from '../../api';
// IMPORT COMPONENTS
import './Programme.css';
import ProgrammesCompo from '../../components/actions/ProgrammesCompo';
import SeancesCompo from '../../components/actions/SeancesCompo';

// Imports FontAwesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';

import { faChevronLeft, faUser } from '@fortawesome/pro-solid-svg-icons';
import { faMagnifyingGlass, faBarsFilter } from '@fortawesome/pro-regular-svg-icons';
import { faFaceThinking } from '@fortawesome/pro-light-svg-icons';

library.add(faChevronLeft, faMagnifyingGlass, faBarsFilter, faFaceThinking, faUser);

const Programme = () => {
  const location = useLocation();
  const isUserFromDashboardResearch = location.state?.isModalOpen;
  const [searchValue, setSearchValue] = useState('');

  const [niveauFilter, setNiveauFilter] = useState([]);
  const [objectifFilter, setObjectifFilter] = useState([]);
  const [figureFilter, setFigureFilter] = useState([]);
  const [zoneFilter, setZoneFilter] = useState([]);
  const [materielFilter, setMaterielFilter] = useState([]);
  const [intensiteFilter, setIntensiteFilter] = useState([]);
  const [dureeFilter, setDureeFilter] = useState([]);
  const [filterByType, setFilterByType] = useState('programmes');

  const filterFromDashboard = location.state?.filterFromCardSlide;

  useEffect(() => {
    if (!filterFromDashboard) return;
    sessionStorage.removeItem('searchValue');
    sessionStorage.removeItem('filters');
    if (filterFromDashboard.figures) {
      setFigureFilter((prev) => [...prev, filterFromDashboard.figures]);
    }
    if (filterFromDashboard.niveaux) {
      setNiveauFilter((prev) => [...prev, filterFromDashboard.niveaux]);
    }
    if (filterFromDashboard.objectifs) {
      setObjectifFilter((prev) => [...prev, filterFromDashboard.objectifs]);
    }
    if (filterFromDashboard.materiels) {
      setMaterielFilter((prev) => [...prev, filterFromDashboard.materiels]);
    }
    if (filterFromDashboard.zones) {
      setZoneFilter((prev) => [...prev, filterFromDashboard.zones]);
    }
    // add new conditon if there is nex cardSLide filter
  }, [filterFromDashboard]);

  useEffect(() => {
    const storedSearch = sessionStorage.getItem('searchValue');
    const filterByType = sessionStorage.getItem('filterByType');
    const storedFilters = JSON.parse(sessionStorage.getItem('filters'));
    if (filterFromDashboard) {
      setFilterByType('programmes');
      return;
    }

    if (storedSearch) {
      setSearchValue(storedSearch);
    }
    if (storedFilters) {
      setFilterByType(filterByType);
      setNiveauFilter(storedFilters.niveauFilter || []);
      setObjectifFilter(storedFilters.objectifFilter || []);
      setFigureFilter(storedFilters.figureFilter || []);
      setZoneFilter(storedFilters.zoneFilter || []);
      setMaterielFilter(storedFilters.materielFilter || []);
      setIntensiteFilter(storedFilters.intensiteFilter || []);
      setDureeFilter(storedFilters.dureeFilter || []);
    }
  }, [filterFromDashboard]);

  // Sauvegarde des filtres et de la valeur de recherche dans sessionStorage
  useEffect(() => {

    // Si l'utilisateur provient de `filterFromCardSlide`, on ne sauvegarde pas
    if (filterFromDashboard) return;

    sessionStorage.setItem('searchValue', searchValue);
    sessionStorage.setItem('filterByType', filterByType);
    sessionStorage.setItem(
      'filters',
      JSON.stringify({
        niveauFilter,
        objectifFilter,
        figureFilter,
        zoneFilter,
        materielFilter,
        intensiteFilter,
        dureeFilter,
      })
    );
  }, [
    searchValue,
    niveauFilter,
    objectifFilter,
    figureFilter,
    zoneFilter,
    materielFilter,
    intensiteFilter,
    dureeFilter,
    filterByType,
    filterFromDashboard,
  ]);

  const [modalSearch, setModalSearch] = useState(false);

  const [isRefetching, setIsRefetching] = useState(false);

  const navigate = useNavigate();

  const handleFilterChange = (event) => {
    setFilterByType(event.target.value);
  };

  const { data: getFilter, loading: loadingGetFilter } = useQuery(GET_FILTERS_QUERY, {
    variables: {
      search: searchValue,
      niveau: niveauFilter,
      figure: figureFilter,
      zone: zoneFilter,
      materiel: materielFilter,
      ...(filterByType === 'programmes' && { objectif: objectifFilter }), // Ajoutez objectifs uniquement si filterByType est "programmes"
      ...(filterByType === 'seances' && { intensite: intensiteFilter }), // Ajoutez intensites uniquement si filterByType est "seances"
      ...(filterByType === 'seances' && { duree: dureeFilter }), // Ajoutez durees uniquement si filterByType est "seances"
    },
  });

  const {
    loading: loadingFilter,
    data,
    error: errorToken,
    refetch: refetchProgrammes,
  } = useQuery(filterByType === 'programmes' ? GET_PROGRAMMES_QUERY : GET_SEANCES_QUERY, {
    variables: {
      search: searchValue,
      niveaux: niveauFilter,
      figures: figureFilter,
      zones: zoneFilter,
      materiels: materielFilter,
      ...(filterByType === 'programmes' && { objectifs: objectifFilter }), // Ajoutez objectifs uniquement si filterByType est "programmes"
      ...(filterByType === 'seances' && { intensites: intensiteFilter }), // Ajoutez intensites uniquement si filterByType est "seances"
      ...(filterByType === 'seances' && { durees: dureeFilter }), // Ajoutez durees uniquement si filterByType est "seances"
    },
  });

  useEffect(() => {
    if (isUserFromDashboardResearch && !loadingGetFilter) {
      setModalSearch(true);
      // Reset state isUserFromDashboardResearch true
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [isUserFromDashboardResearch, loadingGetFilter]);

  const handleProgrammeClick = (id) => navigate(`/entrainements/programme/${id}`);
  const handleClickSeance = (id) => navigate(`/entrainements/seance/${id}`);

  const handleCheckboxChange = (value, filterState, setFilterState) => {
    setFilterState((prevFilters) =>
      filterState.includes(value) ? prevFilters.filter((v) => v !== value) : [...prevFilters, value]
    );
  };

  const handleSearchModal = async (e) => {
    e.preventDefault();
    setModalSearch(false);
    try {
      setIsRefetching(true);
      await refetchProgrammes({
        search: searchValue,
        niveaux: niveauFilter,
        figures: figureFilter,
        zones: zoneFilter,
        materiels: materielFilter,
        ...(filterByType === 'programmes' && { objectifs: objectifFilter }), // Ajoutez objectifs uniquement si filterByType est "programmes"
        ...(filterByType === 'seances' && { intensites: intensiteFilter }), // Ajoutez intensites uniquement si filterByType est "seances"
        ...(filterByType === 'seances' && { durees: dureeFilter }), // Ajoutez durees uniquement si filterByType est "seances"
      });
      setIsRefetching(false);
    } catch {
      console.log('error refetch');
    }
  };

  const renderCheckbox = (label, value, type, filterState, setFilterState) => (
    <label key={value}>
      {label}
      <input
        type='checkbox'
        value={value}
        checked={filterState.includes(value)}
        onChange={(e) => handleCheckboxChange(value, filterState, setFilterState)}
      />
    </label>
  );

  const filterFunctions = {
    AppConfigFiltresNiveauxLayout: (value) =>
      renderCheckbox(value?.name, value?.slug, 1, niveauFilter, setNiveauFilter),
    AppConfigFiltresObjectifsLayout: (value) =>
      renderCheckbox(value?.name, value?.slug, 2, objectifFilter, setObjectifFilter),
    AppConfigFiltresFiguresLayout: (value) =>
      renderCheckbox(value?.name, value?.slug, 3, figureFilter, setFigureFilter),
    AppConfigFiltresZonesCorporellesLayout: (value) =>
      renderCheckbox(value?.name, value?.slug, 4, zoneFilter, setZoneFilter),
    AppConfigFiltresMaterielsLayout: (value) =>
      renderCheckbox(value?.name, value?.slug, 5, materielFilter, setMaterielFilter),
    AppConfigFiltresIntensitesLayout: (value) =>
      renderCheckbox(value?.name, value?.slug, 6, intensiteFilter, setIntensiteFilter),
    AppConfigFiltresDureesLayout: (value) =>
      renderCheckbox(value?.name, value?.slug, 7, dureeFilter, setDureeFilter),
  };

  const filterLabels = {
    AppConfigFiltresNiveauxLayout: 'Niveaux',
    AppConfigFiltresObjectifsLayout: 'Objectifs',
    AppConfigFiltresFiguresLayout: 'Figures',
    AppConfigFiltresZonesCorporellesLayout: 'Zones Corporelles',
    AppConfigFiltresMaterielsLayout: 'Matériels',
    AppConfigFiltresIntensitesLayout: 'Intensités',
    AppConfigFiltresDureesLayout: 'Durées',
  };

  const handleCloseModalByOverlay = (e) => {
    e.stopPropagation();
    setModalSearch(false);
  };

  // Fonction pour calculer le pourcentage de progression
  const calculateProgression = (totalSeances, completedSeances) => {
    if (totalSeances === 0) return 0;
    return (completedSeances / totalSeances) * 100;
  };

  const avancementUser = data?.viewer?.userDatas?.avancement;
  const viewerIsFreemium = data?.viewer?.userDatas?.freemiumSubscription;
  const viewerIsAdmin = data?.viewer?.roles?.nodes?.some((role) => role.name === "administrator");

  return (
    <div className='w-full'>

      <Helmet>
        <title>Entraînements</title>
      </Helmet>

      <div className='topBloc background sticked content'>
        <div className='leftSide'>
          <h1>Entraînements</h1>
        </div>

        <div className='rightSide'>
          <div
            className='IconProfil lg:hidden'
            onClick={() => {
              navigate('/profil');
            }}
          >
            <FontAwesomeIcon icon='fa-solid fa-user' />
          </div>
        </div>

        <div onClick={() => setModalSearch(true)} className='flex-full mt-4 md:mt-5 searchForm'>
          <FontAwesomeIcon className='searchIcon' icon='fa-regular fa-magnifying-glass' />
          <p className='label'>Rechercher un programme ou une séance</p>
          <div className='searchInput' />
          <FontAwesomeIcon className='filterIcon' icon='fa-regular fa-bars-filter' />
        </div>
      </div>

      <div className='blurredPattern brown'></div>

      {loadingFilter || isRefetching || loadingGetFilter ? (
        <div className='contentProgrammes loading'>
          <FontAwesomeIcon className='animate-spin' icon='fa-duotone fa-spinner-third' />
        </div>
      ) : (
        <>
          {data && data[filterByType]?.nodes?.length === 0 && (
            <div className='contentProgrammes noResults'>
              <FontAwesomeIcon icon='fa-light fa-face-thinking' />
              <p>Aucun résultat trouvé pour cette recherche.</p>
            </div>
          )}
          <div className='contentProgrammes content'>
            {filterByType === 'programmes' &&
              data?.programmes?.nodes?.map((programme, index) => {
                
                const nbSeances = programme?.seances?.planificationProgramme?.reduce(
                  (acc, planification) =>
                    acc +
                    (planification?.synchProgrammesSeances
                      ? planification?.synchProgrammesSeances?.nodes?.filter(
                          (seance) => viewerIsAdmin || seance.status === "publish"
                        ).length
                      : 0),
                  0
                );             

                const filteredData = avancementUser?.filter(
                  (item) => item.programmeId === programme?.databaseId.toString()
                );
                const completedSeances = filteredData?.reduce(
                  (total, item) => total + item.seances?.length,
                  0
                );
                const avancementPerCent = Math.round(
                  calculateProgression(nbSeances, completedSeances)
                );

                const isAccessible = viewerIsFreemium ? programme?.datasProgrammes?.enableFreemiumAccount : true;

                return (
                  <ProgrammesCompo
                    onClick={() => handleProgrammeClick(programme?.id)}
                    key={index}
                    niveau={programme?.niveaux?.edges?.[0]?.node?.name}
                    niveauSlug={programme?.niveaux?.edges?.[0]?.node?.slug}
                    label={programme?.title}
                    nbSeance={nbSeances}
                    src={programme?.thumbnailsProgramme?.thumbnailProgrammePaysage?.node?.sourceUrl}
                    srcSet={programme?.thumbnailsProgramme?.thumbnailProgrammePaysage?.node?.srcSet}
                    alt={programme?.thumbnailsProgramme?.thumbnailProgrammePaysage?.node?.altText}
                    id={programme?.id}
                    {...(viewerIsAdmin && { status: programme?.status })}
                    avancement={avancementPerCent}
                    freemiumAccess={isAccessible}
                  />
                );
              })}
            {filterByType === 'seances' &&
              data?.seances?.nodes?.map((seance, index) => {

                const isAccessible = viewerIsFreemium ? seance?.datasSeances?.enableFreemiumAccount : true;
                
                return (
                  <SeancesCompo
                    onClick={() => handleClickSeance(seance?.id)}
                    key={index}
                    duree={seance?.datasSeances?.dureeSeance}
                    niveau={seance?.niveaux?.edges?.[0]?.node?.name}
                    niveauSlug={seance?.niveaux?.edges?.[0]?.node?.slug}
                    intensite={seance?.intensites?.edges?.[0]?.node?.name}
                    intensiteSlug={seance?.intensites?.edges?.[0]?.node?.slug}
                    label={seance?.title}
                    src={seance?.thumbnailSeance?.thumbnailSeancePaysage?.node?.sourceUrl}
                    srcSet={seance?.thumbnailSeance?.thumbnailSeancePaysage?.node?.srcSet}
                    alt={seance?.thumbnailSeance?.thumbnailSeancePaysage?.node?.altText}
                    id={seance?.id}
                    {...(viewerIsAdmin && { status: seance?.status })}
                    freemiumAccess={isAccessible}
                  />
                );
              })}
          </div>
        </>
      )}

      {modalSearch && (
        <div className='SearchFilter' onClick={handleCloseModalByOverlay}>
          <div className='overlaypopUpSearch'></div>

          <div className='popUpSearch' onClick={(e) => e.stopPropagation()}>
            <h2>Rechercher et filtrer</h2>

            <form>
              <div className='filtersSide'>
                <div className='BlockSearchType'>
                  <label>
                    <input
                      type='radio'
                      name='filterByType'
                      value='programmes'
                      checked={filterByType === 'programmes'}
                      onChange={handleFilterChange}
                    />
                    Programmes
                  </label>
                  <label>
                    <input
                      type='radio'
                      name='filterByType'
                      value='seances'
                      checked={filterByType === 'seances'}
                      onChange={handleFilterChange}
                    />
                    Séances
                  </label>
                </div>

                <div className='flex-full border border-border searchForm'>
                  <FontAwesomeIcon className='searchIcon' icon='fa-regular fa-magnifying-glass' />
                  <input
                    className='searchInput'
                    type='text'
                    onChange={(e) => setSearchValue(e.target.value)}
                    value={searchValue}
                    placeholder='Rechercher un mot-clé'
                  />
                </div>

                {filterByType === 'programmes' && (
                  <div className='filterByProgramme'>
                    {getFilter?.configurations?.appConfig?.filtres.map((filtres) => (
                      <>
                        {filtres.__typename !== 'AppConfigFiltresIntensitesLayout' &&
                          filtres.__typename !== 'AppConfigFiltresDureesLayout' && ( // Ne pas afficher Intensité pour les programmes
                            <div className='BlockSearch'>
                              <p className='label'>
                                {filtres.__typename && filterLabels[filtres.__typename]}
                              </p>
                              {filtres?.__typename &&
                                filterFunctions[filtres.__typename] &&
                                filtres.terms.nodes.map((value) =>
                                  filterFunctions[filtres.__typename](value)
                                )}
                            </div>
                          )}
                      </>
                    ))}
                  </div>
                )}

                {filterByType === 'seances' && (
                  <div className='filterBySeance'>
                    {getFilter?.configurations?.appConfig?.filtres.map((filtres) => (
                      <>
                        {filtres.__typename !== 'AppConfigFiltresObjectifsLayout' && ( // Ne pas afficher Objectif pour les séances
                          <div className='BlockSearch'>
                            <p className='label'>
                              {filtres.__typename && filterLabels[filtres.__typename]}
                            </p>
                            {filtres?.__typename &&
                              filterFunctions[filtres.__typename] &&
                              filtres.terms.nodes.map((value) =>
                                filterFunctions[filtres.__typename](value)
                              )}
                          </div>
                        )}
                      </>
                    ))}
                  </div>
                )}
              </div>

              <button onClick={handleSearchModal} className='submitSearch btn'>
                Rechercher
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Programme;
