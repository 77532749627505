import React,  { useState } from 'react';
import Cropper from 'react-easy-crop'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CropImageModal = ({ closeModal, image, setCropped, disposition = 1/2, setIsCropped}) => {

    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

    const CROP_AREA_ASPECT = disposition ? 1 / 2 : 2 / 1;

    const onCropComplete = (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
      }

    const createImage = (url) =>
        new Promise((resolve, reject) => {
            const image = new Image()
            image.addEventListener('load', () => resolve(image))
            image.addEventListener('error', (error) => reject(error))
            image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
            image.src = url
    })

    async function getCroppedImg(
        imageSrc,
        pixelCrop,
    ) {
        const image = await createImage(imageSrc)
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')

        if (!ctx) {
          return null
        }

        // set canvas size to match the bounding box
        canvas.width =  image.width
        canvas.height = image.height

        // draw rotated image
        ctx.drawImage(image, 0, 0)

        const croppedCanvas = document.createElement('canvas')

        const croppedCtx = croppedCanvas.getContext('2d')

        if (!croppedCtx) {
          return null
        }

        // Set the size of the cropped canvas
        croppedCanvas.width = pixelCrop.width
        croppedCanvas.height = pixelCrop.height

        // Draw the cropped image onto the new canvas
        croppedCtx.drawImage(
          canvas,
          pixelCrop.x,
          pixelCrop.y,
          pixelCrop.width,
          pixelCrop.height,
          0,
          0,
          pixelCrop.width,
          pixelCrop.height
        )

        // As Base64 string
        return croppedCanvas.toDataURL('image/jpeg');
    }

    const handleCrop = () => {
        getCroppedImg(image, croppedAreaPixels).then((croppedImage) => {
            setCropped(croppedImage);
            setIsCropped(true);
            closeModal();
        })
    }
    return (
        <div className='modalShowProgression'>
            <Cropper
                image={image}
                crop={crop}
                zoom={zoom}
                aspect={CROP_AREA_ASPECT}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
            />
            <div className="absolute bottom-12 flex gap-2">
                <button onClick={handleCrop}>
                    <FontAwesomeIcon className="bg-white text-green w-8 p-2 h-8 p-2 rounded-full z-10" icon='fa-solid fa-check' />
                </button>
                <button onClick={closeModal}>
                    <FontAwesomeIcon className="bg-peach text-white w-8 p-2 h-8 p-2 rounded-full z-10" icon='fa-solid fa-xmark' />
                </button>
            </div>
        </div>
    );
};

export default CropImageModal;