// STORE stockage de token du user
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  token: null,
  id: null,
  databaseId: null,
  refreshToken: null,
};

export const usersSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login: (state, action) => {
      state.token = action.payload.token;
      state.id = action.payload.id;
      state.databaseId = action.payload.databaseId;
      state.refreshToken = action.payload.refreshToken;
    },
    logout: (state) => {
      state.token = null;
      state.refreshToken = null;
    },
  },
});

export const { login, logout } = usersSlice.actions;
export default usersSlice.reducer;
