// IMPORT REACT
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from './../../store/userSlice';

// IMPORT API & APOLLO
import { useQuery } from '../../hooks/useQuery';
import { GET_USER_QUERY, GET_ONBOARDING_QUERY } from '../../api';

// IMPORT COMPONENTS
import './Profil.css';
import OnBoarding from '../../components/OnBoarding';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTrash, faUser } from '@fortawesome/pro-solid-svg-icons';
import { faArrowUpRight } from '@fortawesome/pro-regular-svg-icons';

library.add(faTrash, faArrowUpRight, faUser);

export default function Profil() {
  const [openOnboarding, setOpenOnboarding] = useState(false);
  const { data: getOnboarding } = useQuery(GET_ONBOARDING_QUERY);
  const { loading, error: errorToken, data } = useQuery(GET_USER_QUERY);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const profil = data?.viewer;
  const email = profil?.email;
  const firstname = profil?.firstName;
  const lastname = profil?.lastName;
  const avatarUrl = profil?.avatar?.url;

  return (
    <div className='w-full relative pageProfil'>
      {openOnboarding && (
        <OnBoarding
          steps={getOnboarding?.onboardings?.nodes}
          closeModal={() => {
            setOpenOnboarding(false);
          }}
        />
      )}

      <Helmet>
        <title>Profil</title>
      </Helmet>

      <div className='topBloc background sticked content'>
        <div className='leftSide'>
          <h1>Profil</h1>
        </div>

        <div className='rightSide'>
          <div
            className='IconProfil lg:hidden'
            onClick={() => {
              navigate('/profil');
            }}
          >
            <FontAwesomeIcon icon='fa-solid fa-user' />
          </div>
        </div>
      </div>

      {loading ? (
        <div className='contentProfil content'>
          <div className='loading'>
            <FontAwesomeIcon className='animate-spin' icon='fa-duotone fa-spinner-third' />
          </div>
        </div>
      ) : (
        <div className='contentProfil content'>
          <div className='userInfos'>
            <div className='avatar'>
              <img src={avatarUrl} alt='avatar' />
            </div>
            <div className='user'>
              <p className='name'>
                {firstname} {lastname}
              </p>
              <p className='email'>{email}</p>
            </div>
          </div>

          <h3>Mon compte</h3>
          <ul>
            <li
              onClick={() => {
                navigate('/favoris');
              }}
            >
              Mes favoris
            </li>
            <li>
              <a
                className='externalLink hasIcon'
                href='https://www.flexifit.app/mon-compte/modifier-compte/'
                target='_blank'
              >
                Gérer mes informations personnelles
                <FontAwesomeIcon icon='fa-regular fa-arrow-up-right' />
              </a>
            </li>
            <li>
              <a
                className='externalLink hasIcon'
                href='https://www.flexifit.app/mon-compte/modifier-compte/'
                target='_blank'
              >
                Modifier mon mot de passe
                <FontAwesomeIcon icon='fa-regular fa-arrow-up-right' />
              </a>
            </li>
          </ul>

          <h3>Mon abonnement</h3>
          <ul>
            <li>
              <a
                className='externalLink hasIcon'
                href='https://www.flexifit.app/mon-compte/abonnements/'
                target='_blank'
              >
                Gérer mon abonnement
                <FontAwesomeIcon icon='fa-regular fa-arrow-up-right' />
              </a>
            </li>
          </ul>

          <h3>Aide & contact</h3>
          <ul>
            <li onClick={() => setOpenOnboarding(true)}>Revoir le tutoriel</li>
            <li>
              <a
                className='externalLink hasIcon'
                href='https://www.flexifit.app/mentions-legales/'
                target='_blank'
              >
                Mentions légales
                <FontAwesomeIcon icon='fa-regular fa-arrow-up-right' />
              </a>
            </li>
            <li>
              <a
                className='externalLink hasIcon'
                href='https://www.flexifit.app/conditions-generales-vente/'
                target='_blank'
              >
                Conditions générales de ventes
                <FontAwesomeIcon icon='fa-regular fa-arrow-up-right' />
              </a>
            </li>
            <li>
              <a
                className='externalLink hasIcon'
                href='https://www.flexifit.app/conditions-generales-vente/'
                target='_blank'
              >
                Conditions d'utilisation
                <FontAwesomeIcon icon='fa-regular fa-arrow-up-right' />
              </a>
            </li>
            <li>
              <a
                className='externalLink hasIcon'
                href='https://www.flexifit.app/politique-de-confidentialite/'
                target='_blank'
              >
                Politique de confidentialité
                <FontAwesomeIcon icon='fa-regular fa-arrow-up-right' />
              </a>
            </li>
          </ul>

          <button
            className='btn logout'
            onClick={() => {
              dispatch(logout());
            }}
          >
            Déconnexion
          </button>

          <a
            className='btn-link icon delete'
            href='https://www.flexifit.app/mon-compte/wpf-delete-account/'
            target='_blank'
          >
            <FontAwesomeIcon icon='fa-solid fa-trash' /> Supprimer mon compte
          </a>
        </div>
      )}
    </div>
  );
}
