import React from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

// IMPORT COMPONENTS 
import './Abonnement.css';
import BackgroundImage from './../../assets/JessicaPrevalet-Flexifit-Background.jpg';


// Imports FontAwesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';

import { faChevronLeft, faUser} from '@fortawesome/pro-solid-svg-icons';
import { faMagnifyingGlass, faBarsFilter, faChartSimple } from '@fortawesome/pro-regular-svg-icons';


library.add(faChevronLeft, faMagnifyingGlass, faBarsFilter, faChartSimple, faUser);

export default function Abonnement() {

  const navigate = useNavigate();

  return (
    <div className='h-screen w-screen flex flex-wrap'>

      <Helmet>
        <title>Abonnement Flexifit</title>
      </Helmet>

      <div className='flex-left flex flex-col items-center justify-center px-5 py-8 lg:p-12'>
        
        <p className='alert !w-fit'>Vous n'êtes plus abonné ? Veuillez vérifier dans votre compte client si votre abonnement est bien actif.</p>

        <div className='my-auto w-full mx-auto sm:w-[650px] text-center'>

          <h1 className='text-[27px] md:text-[32px] leading-tight mb-4 md:mb-6'>Oh ! Tu n’es plus abonné ? Rejoins-nous à nouveau !</h1>
          <p className='text-[16px] md:text-[18px] mb-3 md:mb-4'>
          Ton abonnement semble avoir expiré, mais nos programmes et séances t’attendent pour t’aider à atteindre tous tes objectifs !
          </p>
          <p className='text-[16px] md:text-[16px] mb-6 md:mb-8 font-light'>
          Que tu souhaites un abonnement mensuel, trimestriel ou annuel, choisis la formule qui s’adapte le mieux à ton rythme et reprends tes séances dès aujourd’hui !
          </p>

          <a className='btn md:!w-fit' target="_blank" href='https://flexifit.app/'>
            Je me réabonne
          </a>
        </div>

        <div className='text-center'>
            <p>Si tu rencontres un problème, clique ici pour tenter de te reconnecter.</p>
            <button
              className='btn-link'
              onClick={() => {
                navigate('/login');
              }}
            >
              Je me reconnecte
            </button>
          </div>

      </div>

      <div className='hidden lg:block flex-right bg-peach relative overflow-hidden'>
        <img className='object-cover object-bottom absolute top-0 left-0 w-full h-full' src={BackgroundImage} alt='Abonnements Flexifit' />
      </div>

    </div>
  );
}
