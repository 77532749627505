import { REFRESH_TOKEN_MUTATION } from "../api";
import { useMutation } from "@apollo/client";
import { useSelector } from "react-redux";

export const useRefreshToken = () => {
    const refreshToken = useSelector((state) => state.user.refreshToken);

    const [refreshTokenMutation] = useMutation(REFRESH_TOKEN_MUTATION);

    const refresh = async () => {
        try {
            const response = await refreshTokenMutation({ variables: { refreshToken: refreshToken } });

            if (response.data) {
                const newToken = response.data.refreshJwtAuthToken.authToken;
                return newToken
            } else {
                throw new Error("Erreur lors du rafraîchissement du token");
            }
        } catch (error) {
            console.error("Erreur lors de la requête de rafraîchissement du token", error);
            throw error; // Propager l'erreur
        }
    };

    return refresh; // On retourne simplement la fonction
};