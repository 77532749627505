// IMPORT REACT
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
// IMPORT API & APOLLO
import { useQuery } from '../../hooks/useQuery';
import { GET_CONSEILS_QUERY } from '../../api';
// IMPORTS UTILS
import { removeAccents } from '../../utils/stringsUtils';
// IMPORT COMPONENTS
import './Conseil.css';
import ConseilsCompo from '../../components/actions/ConseilsCompo';

// Imports FontAwesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';

import { faChevronLeft, faUser } from '@fortawesome/pro-solid-svg-icons';
import { faMagnifyingGlass, faBarsFilter } from '@fortawesome/pro-regular-svg-icons';
import { faFaceThinking } from '@fortawesome/pro-light-svg-icons';

library.add(faChevronLeft, faMagnifyingGlass, faBarsFilter, faFaceThinking, faUser);

export default function Conseil() {
  const { data, loading, error: errorToken } = useQuery(GET_CONSEILS_QUERY);

  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState('');
  const [selectedRubrique, setSelectedRubrique] = useState(null);

  const rubriques = data?.rubriques?.nodes;
  const conseils = data?.conseils?.nodes;

  const viewerIsFreemium = data?.viewer?.userDatas?.freemiumSubscription;

  const filteredConseils = conseils?.filter(conseil =>
    (!selectedRubrique || conseil.rubriques.nodes.some(rubrique => rubrique.slug === selectedRubrique)) &&
    (!searchValue || removeAccents(conseil.title.toLowerCase()).includes(removeAccents(searchValue.toLowerCase())))
  );

  const stickyConseils = filteredConseils?.filter(conseil => conseil.configConseils.stickySite);
  const regularConseils = filteredConseils?.filter(conseil => !conseil.configConseils.stickySite);

  const displayConseils = searchValue.length > 0 ? regularConseils : regularConseils;

  const handleConseilClick = (id) => {
    navigate(`/conseils/${id}`);
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchValue(value);
  };

  const handleRubriqueClick = (rubriqueSlug) => {
    setSelectedRubrique(rubriqueSlug === selectedRubrique ? null : rubriqueSlug);
  };

  return (
    <div className='w-full'>

      <Helmet>
        <title>Conseils</title>
      </Helmet>

      <div className='topBloc background sticked content'>
        <div className='leftSide'>
          <h1>Conseils</h1>
        </div>

        <div className='rightSide'>
          <div
            className='IconProfil lg:hidden'
            onClick={() => {
              navigate('/profil');
            }}
          ><FontAwesomeIcon icon='fa-solid fa-user' /></div>
        </div>

        <div className='flex-full mt-4 md:mt-5 searchForm'>
          <FontAwesomeIcon className='searchIcon' icon={['far', 'magnifying-glass']} />
          <input
            className='searchInput'
            type='text'
            onChange={handleSearch}
            value={searchValue}
            placeholder='Rechercher un conseil'
          />
        </div>

        {rubriques && (
          <div className="rubriquesFilters">
            {rubriques?.map((rubrique, index) => (
              <button key={index} className={rubrique.slug === selectedRubrique ? 'active' : ''} onClick={() => handleRubriqueClick(rubrique.slug)}>{rubrique?.name}</button>
            ))}
          </div>
        )}

      </div>

      <div className="blurredPattern skin"></div>

      {loading ? (
        <div className='contentConseils loading'>
          <FontAwesomeIcon className='animate-spin' icon={['fad', 'spinner-third']} />
        </div>
      ) : displayConseils.length === 0 && stickyConseils.length === 0 ? (
        <div className='contentConseils noResults'>
          <FontAwesomeIcon icon='fa-light fa-face-thinking' />
          <p>Aucun résultat trouvé pour cette recherche.</p>
        </div>
      ) : (
        <div>
          {stickyConseils.length > 0 && (
            <div className='contentConseils content stickyConseils'>
              <h2 className="titleConseilBlock">À la une</h2>
              <div className='gridConseil'>
                {stickyConseils.map((conseils, index) => (
                  <ConseilsCompo
                    key={index}
                    label={conseils?.title}
                    alt={conseils?.thumbnailsConseil?.thumbnailConseilPaysage?.node?.altText}
                    src={conseils?.thumbnailsConseil?.thumbnailConseilPaysage?.node?.sourceUrl}
                    srcSet={conseils?.thumbnailsConseil?.thumbnailConseilPaysage?.node?.srcSet}
                    id={conseils?.id}
                    onClick={() => handleConseilClick(conseils.id)}
                    sticky={true}
                    freemiumAccess={viewerIsFreemium ? conseils?.configConseils?.enableFreemiumAccount : true}
                  />
                ))}
              </div>
            </div>
          )}
          {displayConseils.length > 0 && (
            <div className='contentConseils content'>
              <h2 className="titleConseilBlock">Les derniers conseils</h2>
              <div className='gridConseil'>
                {displayConseils.map((conseils, index) => (
                  <ConseilsCompo
                    key={index}
                    label={conseils?.title}
                    alt={conseils?.thumbnailsConseil?.thumbnailConseilPaysage?.node?.altText}
                    src={conseils?.thumbnailsConseil?.thumbnailConseilPaysage?.node?.sourceUrl}
                    srcSet={conseils?.thumbnailsConseil?.thumbnailConseilPaysage?.node?.srcSet}
                    id={conseils?.id}
                    onClick={() => handleConseilClick(conseils.id)}
                    freemiumAccess={ viewerIsFreemium ? conseils?.configConseils?.enableFreemiumAccount : true }
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
