import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { store, persistor } from './store/store.js';
import { PersistGate } from 'redux-persist/integration/react';
import Router from './Router.js';
import { ApolloProvider } from '@apollo/client';
import { client } from './api.js';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

function App() {
  const location = useLocation();
  const isPreview = process.env.REACT_APP_VERCEL_BRANCH_URL === 'flexifit-git-staging-studioevoldevs-projects.vercel.app';
  const isDevelopment = process.env.REACT_APP_VERCEL_BRANCH_URL === 'flexifit-git-develop-studioevoldevs-projects.vercel.app';
  const isLocalhost = window.location.hostname === 'localhost';

  // Initialiser Google Analytics et suivre les pages vues
  useEffect(() => {
    const measurementId = process.env.REACT_APP_GA_MEASUREMENT_ID;
    ReactGA.initialize(measurementId);  // Utilise la variable d'environnement
    ReactGA.send("pageview");  // Pour suivre la première page vue
  }, []);

  // Suivre les pages vues avec l'URL complète
  useEffect(() => {
    const pagePath = window.location.pathname + window.location.search;  // Inclure les paramètres de requête si existants
    const pageURL = window.location.href;  // Obtenir l'URL complète

    ReactGA.send({
      hitType: "pageview",
      page: pagePath,  // Le chemin relatif (pour cohérence)
      location: pageURL,  // URL complète de la page
    });

  }, [location]);

  return (
    <>
      {isPreview && (
        <div className="pillsEnv beta">
          <span>Version Bêta</span>
        </div>
      )}
      {isDevelopment && (
        <div className="pillsEnv">
          <span>Pré-production</span>
        </div>
      )}
      {isLocalhost && (
        <div className="pillsEnv local">
          <span>Développement local</span>
        </div>
      )}
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <ApolloProvider client={client}>
            <Router />
          </ApolloProvider>
        </PersistGate>
      </Provider>
    </>
  );
}

export default App;
