import { ApolloClient, InMemoryCache, createHttpLink, gql, HttpLink, from } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { dispatch } from './store/store';  // Importer dispatch depuis le store
import { logout } from './store/userSlice';  // Importer l'action logout

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    console.log('graphQLErrors', graphQLErrors);
  }
  if (networkError) {
    console.log('Erreur réseau:', networkError);
    //dispatch(logout()); // Déconnecter l'utilisateur en cas d'erreur réseau
  }
});

const link = from([errorLink, new HttpLink({ uri: process.env.REACT_APP_API_URL_PROD })]);

export const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});


// LOGIN & ONBOARDING

export const LOGIN_MUTATION = gql`
  mutation Login($email: String!, $password: String!) {
    login(input: { clientMutationId: "uniqueId", username: $email, password: $password }) {
      authToken
      refreshToken
      user {
        id
        databaseId
        roles {
          nodes {
            name
          }
        }
        userDatas {
          alreadyLoggedIn
        }
      }
    }
  }
`;

export const REFRESH_TOKEN_MUTATION = gql`
  mutation RefreshAuthToken($refreshToken: String!) {
    refreshJwtAuthToken(input: { clientMutationId: "uniqueId", jwtRefreshToken: $refreshToken }) {
      authToken
    }
  }
`;

export const VALIDATE_ONBOARDING_MUTATION = gql`
  mutation UpdateUserLoggedInState($id: ID!) {
    updateUser(input: { id: $id, alreadyLoggedIn: true }) {
      user {
        id
        userDatas {
          alreadyLoggedIn
        }
      }
    }
  }
`;

export const GET_ONBOARDING_QUERY = gql`
  query GetOnboarding {
    onboardings(where: {isPublishedOnApp: true, orderField: true}) {
      nodes {
        datasOnboardings {
          titre
          contenu
        }
        featuredImage {
          node {
            sourceUrl
            srcSet
            altText
          }
        }
      }
    }
  }
`;

// DASHBOARD

export const GET_DASHBORAD_QUERY = gql`
query getDashboard {
  dashboard {
    appDashboard {
      evenement {
        activer
        label
        image {
          node {
            sourceUrl
            srcSet
            altText
          }
        }
        titre
        lien {
          nodes {
            uri
            link
            id
            databaseId
            contentTypeName
            ... on Programme {
              id
              datasProgrammes {
                enableFreemiumAccount
              }
            }
            ... on Seance {
              id
              datasSeances {
                enableFreemiumAccount
              }
            }
            ... on Conseil {
              id
              configConseils {
                enableFreemiumAccount
              }
            }
          }
        }
      }
      programmeAUneApp {
        label
        programme {
          nodes {
            ... on Programme {
              id
              databaseId
              title
              content
              datasProgrammes {
                enableFreemiumAccount
              }
              niveaux {
                edges {
                  node {
                    name
                    slug
                  }
                }
              }
              thumbnailsProgramme {
                thumbnailProgrammePortrait {
                  node {
                    sourceUrl
                    srcSet
                    altText
                  }
                }
              }
              seances {
                planificationProgramme {
                  synchProgrammesSeances {
                    nodes {
                      id
                      databaseId
                      status
                    }
                  }
                }
              }
            }
          }
        }
      }
      slidesApp {
        ... on AppDashboardSlidesAppNiveauxRowLayout {
          fieldGroupName
          titre
          rubrique {
            nodes {
              ... on Niveau {
                id
                databaseId
                name
                slug
                taxonomyName
                termsConfig {
                  imageUne {
                    node {
                      sourceUrl
                      srcSet
                      altText
                    }
                  }
                }
              }
            }
          }
        }
        ... on AppDashboardSlidesAppFiguresRowLayout {
          fieldGroupName
          titre
          rubrique {
            nodes {
              ... on Figure {
                id
                databaseId
                name
                slug
                taxonomyName
                termsConfig {
                  imageUne {
                    node {
                      sourceUrl
                      srcSet
                      altText
                    }
                  }
                }
              }
            }
          }
        }
        ... on AppDashboardSlidesAppMaterielsRowLayout {
          fieldGroupName
          titre
          rubrique {
            nodes {
              ... on Materiel {
                id
                databaseId
                name
                slug
                taxonomyName
                termsConfig {
                  imageUne {
                    node {
                      sourceUrl
                      srcSet
                      altText
                    }
                  }
                }
              }
            }
          }
        }
        ... on AppDashboardSlidesAppObjectifsRowLayout {
          fieldGroupName
          titre
          rubrique {
            nodes {
              ... on Objectif {
                id
                databaseId
                name
                slug
                taxonomyName
                termsConfig {
                  imageUne {
                    node {
                      sourceUrl
                      srcSet
                      altText
                    }
                  }
                }
              }
            }
          }
        }
        ... on AppDashboardSlidesAppZonesCorporellesRowLayout {
          fieldGroupName
          titre
          rubrique {
            nodes {
              ... on ZoneCorporelle {
                id
                databaseId
                name
                slug
                taxonomyName
                termsConfig {
                  imageUne {
                    node {
                      sourceUrl
                      srcSet
                      altText
                    }
                  }
                }
              }
            }
          }
        }
      }
      conseilsUneApp {
        nodes {
          ... on Conseil {
            id
            databaseId
            title
            slug
            configConseils {
              publierApp
              enableFreemiumAccount
            }
            thumbnailsConseil {
              thumbnailConseilPaysage {
                node {
                  sourceUrl
                  srcSet
                  altText
                }
              }
            }
          }
        }
      }
    }
  }
  viewer {
    firstName
    roles {
      nodes {
        name
      }
    }
    userDatas {
      freemiumSubscription
      avancement {
        programmeId
        seances {
          seanceId
        }
      }
    }
  }
 } 
`

// PROGRAMMES & SEANCES

export const GET_PROGRAMMES_QUERY = gql`
  query getProgrammes($niveaux: [String], $objectifs: [String], $figures: [String], $zones: [String], $materiels: [String], $search: String) {
    programmes(
      where: { orderby: {field: DATE, order: DESC}, stati: [PUBLISH, FUTURE, PRIVATE], filterByNiveaux: $niveaux, filterByObjectifs: $objectifs, filterByFigures: $figures, filterByZones: $zones, filterByMateriels: $materiels, search: $search },
      first: 100
    ) {
      nodes {
        id
        databaseId
        date
        status
        title
        slug
        datasProgrammes {
          enableFreemiumAccount
        }
        niveaux {
          edges {
            node {
              name
              slug
            }
          }
        }
        seances {
          planificationProgramme {
            synchProgrammesSeances {
              nodes {
                ... on Seance {
                  id
                  databaseId
                  status
                }
              }
            }
          }
        }
        thumbnailsProgramme {
          thumbnailProgrammePaysage {
            node {
              sourceUrl
              srcSet
              altText
            }
          }
        }
      }
    }
    viewer {
      roles {
        nodes {
          name
        }
      }
      userDatas {
        freemiumSubscription
        avancement {
          programmeId
          seances {
            seanceId
          }
        }
      }
    }
  }
`;

export const GET_PROGRAMME_QUERY = gql`
  query getProgramme($programmeId: ID!) {
    programme(id: $programmeId) {
      id
      databaseId
      title
      slug
      content
      thumbnailsProgramme {
        thumbnailProgrammeDetail {
          node {
            sourceUrl
            srcSet
            altText
          }
        }
        thumbnailProgrammeDetailDesktop {
          node {
            sourceUrl
            srcSet
            altText
          }
        }
      }   
      niveaux {
        edges {
          node {
            name
            slug
          }
        }
      }
      durees {
        edges {
          node {
            name
            slug
          }
        }
      }
      figures {
        edges {
          node {
            name
            slug
          }
        }
      }
      objectifs {
        edges {
          node {
            name
            slug
          }
        }
      }
      materiels {
        edges {
          node {
            name
            slug
          }
        }
      }
      datasProgrammes {
        introductionProgramme
        enableFreemiumAccount
      }
      lienConseilProgrammes {
        lienConseil {
          nodes {
            ... on Conseil {
              id
              databaseId
              title
              slug
              configConseils {
                publierApp
                enableFreemiumAccount
              }
            }
          }
        }
      }
      seances {
        planificationProgramme {
          titre
          synchProgrammesSeances {
            nodes {
              ... on Seance {
                id
                databaseId
                title
                status
                thumbnailSeance {
                  thumbnailSeanceDetail {
                    node {
                      altText
                      sourceUrl
                      srcSet
                    }
                  }
                }
                datasSeances {
                  dureeSeance
                }
              }
            }
          }
        }
      }
    }
    viewer {
      roles {
        nodes {
          name
        }
      }
      userDatas {
        freemiumSubscription
        avancement {
          programmeId
          seances {
            seanceId
          }
        }
      }
    }
  }
`;

export const GET_SEANCES_QUERY = gql`
  query getSeances($niveaux: [String], $intensites: [String], $durees: [String], $figures: [String], $zones: [String], $materiels: [String], $search: String) {
    seances(
      where: { orderby: {field: DATE, order: DESC}, stati: [PUBLISH, FUTURE, PRIVATE], filterByNiveaux: $niveaux, filterByIntensites: $intensites, filterByDurees: $durees, filterByFigures: $figures, filterByZones: $zones, filterByMateriels: $materiels, search: $search },
      first: 100
    ) {
      nodes {
        id
        databaseId
        title
        slug
        status
        thumbnailSeance {
          thumbnailSeancePaysage {
            node {
                altText
                sourceUrl
                srcSet
            }
          }
        }
        intensites {
          edges {
            node {
              name
              slug
            }
          }
        }
        niveaux {
          edges {
            node {
              name
              slug
            }
          }
        }
        datasSeances {
          dureeSeance
          enableFreemiumAccount
        }
      }
    }
    viewer {
      roles {
        nodes {
          name
        }
      }
      userDatas {
        freemiumSubscription
      }
    }
  }
`;

export const GET_SEANCE_QUERY = gql`
  query getSeance($id: ID!) {
    seance(id: $id) {
      id
      databaseId
      title
      slug
      content
      thumbnailSeance {
        thumbnailSeanceDetail {
          node {
              altText
              sourceUrl
              srcSet
          }
        }
        thumbnailSeanceDetailDesktop {
          node {
              altText
              sourceUrl
              srcSet
          }
        }
      }
      niveaux {
        nodes {
          name
          slug
        }
      }
      intensites {
        nodes {
          name
          slug
        }
      }
      materiels {
        edges {
          node {
            name
            slug
          }
        }
      }
      lienConseilSeances {
        lienConseil {
          nodes {
            ... on Conseil {
              id
              databaseId
              title
              slug
              configConseils {
                publierApp
              }
            }
          }
        }
      }
      datasSeances {
        enableFreemiumAccount
        dureeSeance
        labelSeance
        urlSeance
        labelSeance2
        urlSeance2
        enableCoolDown
        coolDown {
          urlVideo
          titre
          duree
        }
      }
    }
    viewer {
      userDatas {
        freemiumSubscription
        avancement {
          programmeId
          seances {
            seanceId
          }
        }
        avancementSeances {
          seanceId
        }
      }
    }
  }
`;

export const POST_UPDATE_AVANCEMENT_MUTATION = gql`
  mutation updateAvancement($id: ID!, $updateAvancement: [String], $updateAvancementSeance: [String]) {
    updateUser(input: {
      id: $id,
      updateAvancement: $updateAvancement,
      updateAvancementSeance: $updateAvancementSeance
    }) {
      user {
        id
        userDatas {
          avancement {
            programmeId
            seances {
              seanceId
            }
          }
          avancementSeances {
            seanceId
          }
        }
      }
    }
  }
`;


// CONSEILS

export const GET_CONSEILS_QUERY = gql`
  query getConseils($rubriques: [String]) {
    rubriques {
      nodes {
        slug
        name
        id
      }
    }
    conseils(
      where: {filterByRubriques: $rubriques, isPublishedOnApp: true, stickyField: true},
      first: 100
    ) {
      nodes {
        id
        databaseId
        date
        title
        slug
        content
        configConseils {
          enableFreemiumAccount
        }
        configConseils {
          stickySite
        }
        rubriques {
          nodes {
            slug
            name
          }
        }
        thumbnailsConseil {
          thumbnailConseilPaysage {
            node {
              sourceUrl
              srcSet
              altText           
            }
          }
        }
      }
    }
    viewer {
      userDatas {
        freemiumSubscription
      }
    }   
  }
`;

export const GET_CONSEIL_QUERY = gql`
  query getConseil($id: ID!) {
    conseil(id: $id) {
      id
      databaseId
      date
      title
      slug
      content(format: RENDERED)
      thumbnailsConseil {
        thumbnailConseilDetail {
          node {
            altText
            srcSet
            sourceUrl
          }
        }
        thumbnailConseilDetailDesktop {
          node {
            altText
            srcSet
            sourceUrl
          }
        }
      }
      configConseils {
        publierApp
        enableFreemiumAccount
      }
      datasConseil {
        lien {
          url
          title
        }
        fichier {
          node {
            mediaItemUrl
          }
        }
      }
      lienProgrammeConseils {
        lienProgramme {
          nodes {
            ... on Programme {
              id
              databaseId
              date
              title
              slug
              datasProgrammes {
                enableFreemiumAccount
              }
              niveaux {
                edges {
                  node {
                    name
                    slug
                  }
                }
              }
              seances {
                planificationProgramme {
                  synchProgrammesSeances {
                    nodes {
                      ... on Seance {
                        status
                        id
                        databaseId
                      }
                    }
                  }
                }
              }
              thumbnailsProgramme {
                thumbnailProgrammePaysage {
                  node {
                    sourceUrl
                    srcSet
                    altText
                  }
                }
              }
            }
          }
        }
      }
    }
    viewer {
      roles {
        nodes {
          name
        }
      }
      userDatas {
        freemiumSubscription
        avancement {
          programmeId
          seances {
            seanceId
          }
        }
      }
    }
  }
`;

// SUIVI & PROGRESSIONS

export const GET_SUIVIS_QUERY = gql`
  query getSuivi {
    viewer {
      databaseId
      userDatas {
        progression(first: 100) {
          nodes {
            ... on Progression {
              id
              databaseId
              date
              status
              progressionDatas {
                enableUsage
                commentaireProgression
                montageProgression {
                  node {
                    sourceUrl
                  }
                }
              }
              figures {
                nodes {
                  id
                  databaseId
                  slug
                  name
                }
              }
            }
          }
        }
        evolution(first: 100) {
          nodes {
            ... on Evolution {
              id
              databaseId
              date
              status
              evolutionsDatas {
                enableUsage
                commentaireEvolution
                imageEvolution {
                  node {
                    sourceUrl
                  }
                }
              }
              figures {
                nodes {
                  id
                  databaseId
                  slug
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const POST_PROGRESSION_MUTATION = gql`
  mutation postProgression(
    $title: String!
    $avant: String!
    $apres: String!
    $montage: String!
    $disposition: Boolean!
    $figure: Int!
    $usage: Boolean!
    $utilisateur: Int!
    $note: String!
  ) {
    createProgression(
      input: {
        title: $title
        avant: $avant
        apres: $apres
        montage: $montage
        disposition: $disposition
        figure: $figure
        usage: $usage
        utilisateur: $utilisateur
        note: $note
        status: PUBLISH
      }
    )  {
      progression {
        databaseId
        progressionDatas {
          thumbnailAvant {
            node {
              uri
            }
          }
          thumbnailApres {
            node {
              uri
            }
          }
          montageProgression {
            node {
              uri
            }
          }
          disposition
          utilisateur {
            nodes {
              name
            }
          }
          enableUsage
        }
        figures {
          nodes {
            name
          }
        }
      }
    }
   }
`;

export const POST_EVOLUTION_MUTATION = gql`
  mutation postEvolution(
    $title: String!
    $image: String!
    $angleEvolution: Int!
    $figure: Int!
    $utilisateurEvolution: Int!
    $noteEvolution: String!
  ) {
    createEvolution(
      input: {
        title: $title
        image: $image
        angleEvolution: $angleEvolution
        figure: $figure
        utilisateurEvolution: $utilisateurEvolution
        noteEvolution: $noteEvolution
        status: PUBLISH
      }
    )  {
      evolution {
        databaseId
        evolutionsDatas {
          imageEvolution {
            node {
              uri
            }
          }
          utilisateur {
            nodes {
              name
            }
          }
          enableUsage
        }
        figures {
          nodes {
            name
          }
        }
      }
    }
   }
`;

export const GET_FIGURES_SUIVI = gql`
  query getFigures {
    configurations {
      appConfig {
        figuresListProgressions {
          nodes {
            databaseId
            name
            slug
            ... on Figure {
              figuresConfigPosetracker {
                slugPosetracker
              }
            }
          }
        }
      }
    }
  }
`;

export const DELETE_PROGRESSION_MUTATION = gql`
  mutation deleteProgression($id: ID!) {
    updateProgression(input: { id: $id, status: DRAFT }) {
      progression {
        id
        databaseId
        status
      }
    }
  }
`;

export const DELETE_EVOLUTION_MUTATION = gql`
  mutation deleteEvolution($id: ID!) {
    updateEvolution(input: { id: $id, status: DRAFT }) {
      evolution {
        id
        databaseId
        status
      }
    }
  }
`;

// FAVORIS AND ACTION

export const POST_FAVORITE_MUTATION = gql`
  mutation updateFavoris($id: ID!, $updateFavoris: String!) {
    updateUser(input: { id: $id, updateFavoris: $updateFavoris }) {
      user {
        id
        userDatas {
          favoris {
            nodes {
              slug
            }
          }
        }
      }
    }
  }
`;

export const GET_FAVORITE_QUERY = gql`
query getFavoris {
  viewer {
    roles {
      nodes {
        name
      }
    }
    userDatas {
      freemiumSubscription
      avancement {
        programmeId
        seances {
          seanceId
        }
      }
      favoris(first: 100) {
        nodes {
          ... on Programme {
            id
            databaseId
            contentTypeName
            date
            title
            slug
            status
            datasProgrammes {
              enableFreemiumAccount
            }
            niveaux {
              edges {
                node {
                  name
                  slug
                }
              }
            }
            seances {
              planificationProgramme {
                synchProgrammesSeances {
                  nodes {
                    status
                    id
                    databaseId
                  }
                }
              }
            }
            thumbnailsProgramme {
              thumbnailProgrammePaysage {
                node {
                  sourceUrl
                  srcSet
                  altText
                }
              }
            }
          }
          ... on Seance {
            id
            databaseId
            contentTypeName
            date
            title
            slug
            niveaux {
              edges {
                node {
                  name
                  slug
                }
              }
            }
            intensites {
              edges {
                node {
                  name
                  slug
                }
              }
            }
            datasSeances {
              dureeSeance
              enableFreemiumAccount
            }
            thumbnailSeance {
              thumbnailSeancePaysage {
                node {
                    altText
                    sourceUrl
                    srcSet
                }
              }
            }
          }
          ... on Conseil {
            id
            databaseId
            contentTypeName
            date
            title
            slug
            configConseils {
              publierApp
              enableFreemiumAccount
            }
            thumbnailsConseil {
              thumbnailConseilPaysage {
                node {
                  sourceUrl
                  srcSet
                  altText
                }
              }
            }
          }
        }
      }
    }
  }
 }
 
`;

// DATAS USERS

export const GET_USER_QUERY = gql`
  query getUser {
    viewer {
      id
      databaseId
      firstName
      lastName
      email
      avatar {
        url
      }
      jwtRefreshToken
      roles {
        nodes {
          name
        }
      }
    }
  }
`;

// FILTERS

export const GET_FILTERS_QUERY = gql`
query getFilters {
  configurations {
    appConfig {
      filtres {
        ... on AppConfigFiltresNiveauxLayout {
          __typename
          terms {
            nodes {
              name
              slug
            }
          }
        }
        ... on AppConfigFiltresObjectifsLayout {
          __typename
          terms {
            nodes {
              name
              slug
            }
          }
        }
        ... on AppConfigFiltresFiguresLayout {
          __typename
          terms {
            nodes {
              name
              slug
            }
          }
        }
        ... on AppConfigFiltresZonesCorporellesLayout {
          __typename
          terms {
            nodes {
              name
              slug
            }
          }
        }
        ... on AppConfigFiltresMaterielsLayout {
          __typename
          terms {
            nodes {
              name
              slug
            }
          }
        }
        ... on AppConfigFiltresIntensitesLayout {
          __typename
          terms {
            nodes {
              name
              slug
            }
          }
        }
        ... on AppConfigFiltresDureesLayout {
          __typename
          terms {
            nodes {
              name
              slug
            }
          }
        }
      }
    }
  }
}
`;
