import { useQuery as useApolloQuery, useApolloClient } from '@apollo/client';
import { useEffect, useState, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRefreshToken } from './useRefreshToken';
import { login, logout } from '../store/userSlice';
import { GET_USER_QUERY } from '../api';

export const useQuery = (query, options = {}) => {
    const { token, databaseId, id } = useSelector((state) => state.user);
    const refreshTokenState = useSelector((state) => state.user.refreshToken);
    const dispatch = useDispatch();
    const refreshToken = useRefreshToken();
    const client = useApolloClient();

    const [authToken, setAuthToken] = useState(token);
    const isRefreshing = useRef(false); // Pour suivre l'état de rafraîchissement

    // Utiliser useApolloQuery avec le token actuel
    const result = useApolloQuery(query, {
        ...options,
        context: {
            headers: {
                Authorization: `Bearer ${authToken}`
            },
        },
    });

    const refetchWithNewToken = useCallback(async () => {
        if (isRefreshing.current) return; // Empêcher le déclenchement multiple

        try {
            isRefreshing.current = true; // Marquer comme en cours de rafraîchissement
            console.log("Executing refreshToken...");
            const newToken = await refreshToken(); // Rafraîchir le token
            setAuthToken(newToken); // Mettre à jour le token local
            dispatch(login({ token: newToken, refreshToken: refreshTokenState, databaseId, id })); // Mettre à jour le token dans le store
            console.log("Token refreshed successfully!");

            const response = await client.query({
                query : GET_USER_QUERY,
                ...options,
                context: {
                    headers: {
                        Authorization: `Bearer ${newToken}`
                    },
                }
            })

            if(response){
                const role = await response?.data?.viewer?.roles?.nodes[0]?.name;                
                if(role !== 'subscriber' && role !== 'administrator'){                    
                    dispatch(logout());
                }
            }

            // Utiliser client.query pour relancer la requête avec le nouveau token
            await client.query({
                query,
                ...options,
                context: {
                    headers: {
                        Authorization: `Bearer ${newToken}`
                    },
                },
            });
        } catch (refreshError) {
            console.error("Erreur lors de la requête de rafraîchissement du token");
            dispatch(logout()); // Déconnecter l'utilisateur s'il y a une erreur
        } finally {
            isRefreshing.current = false; // Terminer le rafraîchissement
        }
    }, [refreshToken, dispatch, client, query, options]);

    useEffect(() => {
        if (result.error) {
            refetchWithNewToken();
        }
    }, [result.error, refetchWithNewToken]);

    return { ...result, refetchWithNewToken };
};
