import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { convertDateISOToJJMMAAAA } from '../../utils/stringsUtils';
import SuiviCompo from '../actions/SuiviCompo';

const SelectProgressionImageModal = ({ closeModal, setFilterFigure, allFigures, publishedEvolution, handleSrc }) => {

  const handleInnerClick = (e) => {
    e.stopPropagation();
  };

  const [loading, setLoading] = React.useState(false);

  const handleSelection = async (sourceUrl) => {    
    setLoading(true);
    
    try {
      const blob = await fetch(sourceUrl).then(r => r.blob());

        // Convertir le blob en chaîne base64
        const base64 = await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);

            reader.onloadend = () => {
                resolve(reader.result);
            };

            reader.onerror = reject;  // En cas d'erreur, rejeter la promesse
        });

        handleSrc(base64);
    } catch (error) {
        console.error('Erreur lors de la sélection de l\'image :', error);
    } finally {
        closeModal();
        setLoading(false);
    }
  };

  return (
    <div className='contentSuivi modalShowProgression amplitudesModal'>
      <div className='modalInnerShowProgression no-scrollbar' onClick={handleInnerClick}>
        <button className='closeModalProgression' onClick={closeModal}>
          <FontAwesomeIcon icon='fa-regular fa-xmark' />
        </button>
        <h3>Mes amplitudes</h3>
        {
          loading && <div className='loading'>
            <FontAwesomeIcon className='animate-spin' icon='fa-duotone fa-spinner-third' />
          </div>
        }
        {
          !loading &&
          <div className='userProgressions'>
            <div className='topUserProgressions'>
              <select className='chooseFigures' onChange={(e) => setFilterFigure(e.target.value)}>
                <option value=''>Filtrer - Toutes figures</option>
                {allFigures}
              </select>
            </div>

            {
              publishedEvolution.length > 0 ? (
                <div className='galleryUserProgressions'>
                  {publishedEvolution.map((evolution, index) => {
                    const formattedDate = convertDateISOToJJMMAAAA(evolution?.date);
                    return (
                      <>
                        <SuiviCompo
                          key={crypto.randomUUID()}
                          figure={evolution?.figures?.nodes[0]?.name}
                          date={formattedDate}
                          source={evolution?.evolutionsDatas?.imageEvolution?.node.sourceUrl}
                          onClick={() => {
                              handleSelection(evolution?.evolutionsDatas?.imageEvolution?.node.sourceUrl)
                          }}
                        />
                      </>
                    );
                  })}
                </div>
            ) : (
              <div className='galleryUserProgressions noResult'>
                <p>Vous n'avez pas encore d'amplitudes enregistrées</p>
              </div>
            )}

          </div>
        }
        
      </div>
    </div>
  );
};

export default SelectProgressionImageModal;