import React from 'react';
import './ConseilsCompo.css';

import DefaultImage from './../../assets/default-empty-image.svg';

// Imports FontAwesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';

import { faLock } from '@fortawesome/pro-solid-svg-icons';

library.add(faLock);

const ConseilsCompo = ({ src, srcSet, label, id, onClick, alt, sticky, freemiumAccess }) => {
  return (
    <div className={`cardConseil ${sticky ? 'sticky' : ''} ${!freemiumAccess ? "locked" : ""}`} id={freemiumAccess ? id : null} onClick={freemiumAccess ? onClick : null}>
      <div className="thumbConseil">
        {src ? (
            <img src={src} srcSet={srcSet} alt={alt} />
        ) : (
            <img src={DefaultImage}/>
        )}
        {!freemiumAccess && (
          <div className="lockedBadge">
            <div className="lockedBadgeInner">
              <FontAwesomeIcon icon='fa-solid fa-lock' />
              <span>Reservé aux abonnés</span>
            </div>
          </div>
        )}
      </div>
      <h2 className="titleConseil">{label}</h2>
    </div>
  );
};

export default ConseilsCompo;
