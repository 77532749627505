import React from 'react';
import './ProgrammesCompo.css';

import DefaultImage from './../../assets/default-empty-image.svg';

// Imports FontAwesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';

import { faLock, faClock } from '@fortawesome/pro-solid-svg-icons';

library.add(faLock, faClock);

const MAX_LENGTH_MOBILE = 150; // Nombre maximum de caractères à afficher pour les dispositifs mobiles
const MAX_LENGTH_DESKTOP = 450; // Nombre maximum de caractères à afficher pour les dispositifs de bureau

const ProgrammesCompo = ({ src, srcSet, nbSeance, label, id, status, alt, onClick, niveau, niveauSlug, description, avancement, freemiumAccess }) => {
 
  // Vérifiez si la description dépasse la longueur maximale pour les dispositifs mobiles et de bureau
  const isLongDescriptionMobile = description && description.length > MAX_LENGTH_MOBILE;
  const isLongDescriptionDesktop = description && description.length > MAX_LENGTH_DESKTOP;

  // Si la description est longue, tronquez-la et ajoutez "..." à la fin
  const truncatedDescriptionMobile = isLongDescriptionMobile ? description.substring(0, MAX_LENGTH_MOBILE) + '...' : description;
  const truncatedDescriptionDesktop = isLongDescriptionDesktop ? description.substring(0, MAX_LENGTH_DESKTOP) + '...' : description;

  return (
    <div className={`cardProgramme ${!freemiumAccess ? "locked" : ""}`} id={freemiumAccess ? id : null} onClick={freemiumAccess ? onClick : null}>
      <div className="thumbProgramme">
        {src ? (
            <img src={src} srcSet={srcSet} alt={alt} />
        ) : (
            <img src={DefaultImage}/>
        )}
        {!freemiumAccess && (
          <div className="lockedBadge">
            <div className="lockedBadgeInner">
              <FontAwesomeIcon icon='fa-solid fa-lock' />
              <span>Reservé aux abonnés</span>
            </div>
          </div>
        )}
        {status && status !== "publish" && (
          <div className="plannedBadge">
            <div className="plannedBadgeInner">
              <FontAwesomeIcon icon="fa-solid fa-clock" />
              <span>Planifié</span>
            </div>
          </div>
        )}
        {Number.isInteger(avancement) && avancement > 0 && (
          <div className="progressionProgramme">
            <span className="progressBar"><span className="progressBar active" style={{ width: `${avancement}%` }}></span></span>
            <p>{avancement}%</p>
          </div>
        )}
      </div>
      <div className="contentProgrammeInner">
        <p className="titleProgramme">{label}</p>
        <div className="infosProgramme">
          { nbSeance && (
            <p className="seanceProgramme">{nbSeance > 1 ? `${nbSeance} séances` : `${nbSeance} séance`}</p>
          )}
          { niveau && (
            <div className="levelProgramme">
              <div className={`level ${niveauSlug}`}>
                <span></span>
                <span></span>
                <span></span>
              </div>
              <p>{niveau}</p>
            </div>
          )}
        </div>
        { description && (
          <>
            <div
              className='descriptionProgramme blockTextRendered lg:hidden'
              dangerouslySetInnerHTML={{ __html: isLongDescriptionMobile ? truncatedDescriptionMobile : description }}
            ></div>
            <div
              className='descriptionProgramme blockTextRendered hidden lg:block'
              dangerouslySetInnerHTML={{ __html: isLongDescriptionDesktop ? truncatedDescriptionDesktop : description }}
            ></div>
            <span className="seeMore">Voir plus</span>
          </>
        )}
      </div>
    </div>
  );
};

export default ProgrammesCompo;
