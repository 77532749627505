// import REACT
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import InputLogin from '../../components/Inputs/InputLogin';
import { useDispatch } from 'react-redux';

// import API & APOLLO
import { login } from '../../store/userSlice';
import { LOGIN_MUTATION, GET_ONBOARDING_QUERY, VALIDATE_ONBOARDING_MUTATION } from '../../api.js';
import { useMutation, useLazyQuery } from '@apollo/client';

// import COMPONENTS
import './Login.css';
import OnBoarding from '../../components/OnBoarding';
import BackgroundImage from './../../assets/JessicaPrevalet-Flexifit-Background.jpg';


// Imports FontAwesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';

import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';

library.add(faSpinnerThird);

export default function Login() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [localToken, setLocalToken] = useState(null);
  const [localID, setLocalID] = useState(null);
  const [databaseIdLocal, setDatabaseIdLocal] = useState(null);

  const [loginMutation, { loading }] = useMutation(LOGIN_MUTATION);
  const [fetchOnboarding, { data: onboardingData }] = useLazyQuery(GET_ONBOARDING_QUERY);
  const [validateOnboarding] = useMutation(VALIDATE_ONBOARDING_MUTATION);

  const handleLogin = async () => {
    try {
      const response = await loginMutation({ variables: { email, password } });
      const token = await response?.data?.login?.authToken;
      const refreshToken = await response?.data?.login?.refreshToken;
      const isLauchingBoarding = await response?.data?.login?.user?.userDatas?.alreadyLoggedIn;
      const role = await response?.data?.login?.user?.roles?.nodes[0]?.name;
      const idUser = await response?.data?.login?.user?.id;
      const databaseId = await response?.data?.login?.user?.databaseId;
      
      if (role === 'subscriber' || role === 'administrator') {
        if (!isLauchingBoarding) {
          handleOnBoarding(token, idUser, databaseId);
        } else {
          dispatch(
            login({
              token,
              id: idUser,
              databaseId: databaseId,
              refreshToken
            })
          );
        }
      } else if(role === 'customer') {          
        navigate('/abonnement/');
      } else {  
        setErrorMessage('Vous n\'êtes pas abonné. Veuillez vérifier dans votre compte client si votre abonnement est bien actif.');
      }
    } catch (error) {
      const errorType = await error?.graphQLErrors[0]?.message;
      if (errorType === 'invalid_email') {
        setErrorMessage(
          'Votre adresse e-mail ou votre mot de passe semble incorrect. Merci de vérifier vos informations.'
        );
      } else if (errorType === 'incorrect_password') {
        setErrorMessage(
          'Votre adresse e-mail ou votre mot de passe semble incorrect. Merci de vérifier vos informations.'
        );
      } else {
        setErrorMessage('Il semblerait y avoir une erreur. Merci de réessayer.');
      }
    }
  };

  useEffect(() => {
    setErrorMessage(null);
  }, [email, password]);

  const handleOnBoarding = async (token, id, dbId) => {
    await fetchOnboarding({
      context: {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    });
    setIsModalOpen(true);
    setDatabaseIdLocal(dbId);
    setLocalToken(token);
    setLocalID(id);
  };
  const handleCloseModal = async () => {
    const response = await validateOnboarding({
      variables: {
        id: localID,
      },
      context: {
        headers: {
          Authorization: `Bearer ${localToken}`,
        },
      },
    });
    dispatch(
      login({
        token: localToken,
        id: localID,
        databaseId: databaseIdLocal,
      })
    );
    setIsModalOpen(false);
    setLocalToken(null);
    setLocalID(null);
    setDatabaseIdLocal(null);
  };

  return (
    <div className='h-screen w-screen flex flex-wrap'>
      {isModalOpen && (
        <OnBoarding steps={onboardingData?.onboardings?.nodes} closeModal={handleCloseModal} />
      )}

      <Helmet>
        <title>Connexion</title>
      </Helmet>

      <div className='flex-left flex flex-col items-center justify-center px-5 py-12 lg:p-12'>
        <div className='my-auto w-full'>
          <h1 className='md:text-center'>Bienvenue sur Flexifit</h1>
          <p className='md:text-center text-[18px] md:text-[20px] mb-8'>
            Connecte-toi pour commencer l’aventure
          </p>

          <div className='loginform w-full mx-auto sm:w-[450px]'>
            <InputLogin
              label='Adresse e-mail'
              placeholder='Adresse e-mail'
              type='email'
              isPassword={false}
              isEmail={true}
              value={email}
              onChange={setEmail}
            />

            <div className='containerPassword'>
              <InputLogin
                label='Mot de passe'
                placeholder='Mot de passe'
                isPassword={true}
                value={password}
                onChange={setPassword}
              />

              <div className='lostpassword text-right'>
                <a target="_blank" href='https://flexifit.app/mon-compte/mot-de-passe-oublie/'>
                  Mot de passe oublié ?
                </a>
              </div>
            </div>

            <button className='btn' disabled={loading} onClick={() => handleLogin()}>
              {loading ? (
                <span>
                  Connexion en cours <FontAwesomeIcon className='animate-spin' icon='fa-duotone fa-spinner-third' />
                </span>
              ) : (
                <span>
                  Connexion
                </span>
              )}
            </button>

            {errorMessage && <p className='alert'>{errorMessage}</p>}
          </div>
        </div>

        <div className='text-center'>
          <p>Tu n’as pas encore de compte ?</p>
          <a className='btn-link' target="_blank" href='https://flexifit.app/'>
            Inscris-toi dès maintenant !
          </a>
        </div>
      </div>

      <div className='hidden lg:block flex-right bg-peach relative overflow-hidden'>
        <img className='object-cover object-bottom absolute top-0 left-0 w-full h-full' src={BackgroundImage} alt='Connexion Flexifit' />
      </div>
      
    </div>
  );
}
