import React from 'react';
import './EventCompo.css';

import DefaultImage from './../../assets/default-empty-image.svg';

// Imports FontAwesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';

import { faLock, faClock } from '@fortawesome/pro-solid-svg-icons';

library.add(faLock, faClock);

const EventCompo = ({ src, srcSet, alt, titre, id, onClick, freemiumAccess }) => {
 

  return (
    <div className={`cardEvent ${!freemiumAccess ? "locked" : ""}`} id={freemiumAccess ? id : null} onClick={freemiumAccess ? onClick : null}>
      
      <div className="thumbEvent">
        {src ? (
            <img src={src} srcSet={srcSet} alt={alt} />
        ) : (
            <img src={DefaultImage}/>
        )}
        
        {!freemiumAccess && (
          <div className="lockedBadge">
            <div className="lockedBadgeInner">
              <FontAwesomeIcon icon='fa-solid fa-lock' />
              <span>Reservé aux abonnés</span>
            </div>
          </div>
        )}
        
      </div>

      <div className="contentEventInner">
        <p className="titleEvent">{titre}</p>
      </div>

    </div>
  );
};

export default EventCompo;
