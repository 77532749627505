import React from 'react';
import './SeancesCompo.css';

import DefaultImage from './../../assets/default-empty-image.svg';

// Imports FontAwesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';

import { faFire, faSpa, faLock, faClock } from '@fortawesome/pro-solid-svg-icons';

library.add(faFire, faSpa, faLock, faClock);

const SeancesCompo = ({ src, srcSet, label, id, status, alt, onClick, duree, niveau, niveauSlug, intensite, intensiteSlug, freemiumAccess }) => {
 
  return (
    <div className={`cardSeance ${!freemiumAccess ? "locked" : ""}`} id={freemiumAccess ? id : null} onClick={freemiumAccess ? onClick : null}>
      <div className="thumbSeance">
        {src ? (
            <img src={src} srcSet={srcSet} alt={alt} />
        ) : (
            <img src={DefaultImage}/>
        )}
        {!freemiumAccess && (
          <div className="lockedBadge">
            <div className="lockedBadgeInner">
              <FontAwesomeIcon icon='fa-solid fa-lock' />
              <span>Reservé aux abonnés</span>
            </div>
          </div>
        )}
        {status && status !== "publish" && (
          <div className="plannedBadge">
            <div className="plannedBadgeInner">
              <FontAwesomeIcon icon="fa-solid fa-clock" />
              <span>Planifié</span>
            </div>
          </div>
        )}
      </div>
      <div className="contentSeanceInner">
        <p className="titleSeance">{label}</p>
        <div className="infosSeance">
          
          {duree && (
            <div className="dureeSeance">
              <p>{duree}min.</p>
            </div>
          )}
          
          {niveau && (
            <div className="levelSeance">
              <div className={`level ${niveauSlug}`}>
                <span></span>
                <span></span>
                <span></span>
              </div>
              <p>{niveau}</p>
            </div>
          )}

          {intensite && intensite !== 'Récupération' ? (
            <div className='intensiteSeance'>
              <div className={`intensite ${intensiteSlug}`}>
                <FontAwesomeIcon icon='fa-solid fa-fire' />
                <FontAwesomeIcon icon='fa-solid fa-fire' />
                <FontAwesomeIcon icon='fa-solid fa-fire' />
              </div>
              <p>{intensite}</p>
            </div>
          ) : (
            <div className='intensiteSeance'>
              <div className={`intensite ${intensiteSlug}`}>
                <FontAwesomeIcon icon='fa-solid fa-spa' />
              </div>
              <p>{intensite}</p>
            </div>
          )}

        </div>
      </div>
    </div>
  );
};

export default SeancesCompo;
