// IMPORT REACT
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
// IMPORT API & APOLLO
import { useMutation } from '@apollo/client';
import { useQuery } from '../../hooks/useQuery';
import { GET_CONSEIL_QUERY, POST_FAVORITE_MUTATION, GET_FAVORITE_QUERY } from '../../api';

// IMPORT COMPONENTS
import './ConseilsDetails.css';
import DefaultImage from './../../assets/default-empty-image.svg';
import ProgrammesCompo from '../../components/actions/ProgrammesCompo';

// Imports FontAwesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';

import { faChevronLeft, faHeart, faPlay, faUser } from '@fortawesome/pro-solid-svg-icons';

library.add(faChevronLeft, faHeart, faPlay, faUser);

export default function ConseilDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { token, id: idUser } = useSelector((state) => state.user);
  const [favoriteButton, setFavoriteButton] = useState(false);

  const [errorMessageFavorite, setErrorMessageFavorite] = useState(
    'erreur si le like ne fonctionne pas (decommenter le useEffect une fois le style fait)'
  );

  const { data: favoriteData, loading: favoriteLoading } = useQuery(GET_FAVORITE_QUERY);

  const favoriteProgrammes = favoriteData?.viewer?.userDatas?.favoris?.nodes;
  const getIdFavorite = favoriteProgrammes?.map((programme) => programme.id);
  const isidParamsFavorite = getIdFavorite?.includes(id);

  const {
    data,
    loading: loadingConseil,
    error: errorToken,
  } = useQuery(GET_CONSEIL_QUERY, {
    variables: {
      id: id,
    },
  });

  const dispatch = useDispatch();

  const [postFavorite, { loading: postLoadingFavorite }] = useMutation(POST_FAVORITE_MUTATION, {
    context: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  });

  const conseil = data?.conseil;


  // Si le conseil n'est pas accessible, redirige vers /entrainements
  const viewerIsFreemium = data?.viewer?.userDatas?.freemiumSubscription;
  const viewerIsAdmin = data?.viewer?.roles?.nodes?.some((role) => role.name === "administrator");
  const isAccessible = viewerIsFreemium ? conseil?.configConseils?.enableFreemiumAccount : true;

  
  // USE TO REDIRECT IF NOT PUBLISHED
  const conseilPublished = conseil?.configConseils.publierApp;
  
  const databaseIdConseil = conseil?.databaseId;
  const thumbnailMobile = conseil?.thumbnailsConseil?.thumbnailConseilDetail?.node;
  const conseilThumbnailMobileSrc = thumbnailMobile?.sourceUrl;
  const conseilThumbnailMobileSrcSet = thumbnailMobile?.srcSet;
  const conseilThumbnailMobileAlt = thumbnailMobile?.altText;
  const thumbnailDesktop = conseil?.thumbnailsConseil?.thumbnailConseilDetailDesktop?.node;
  const conseilThumbnailDesktopSrc = thumbnailDesktop?.sourceUrl;
  const conseilThumbnailDesktopSrcSet = thumbnailDesktop?.srcSet;
  const conseilThumbnailDesktopAlt = thumbnailDesktop?.altText;
  const conseilTitle = conseil?.title;
  const conseilContent = conseil?.content;

  const conseilHasLien = conseil?.datasConseil?.lien;
  const conseilLienTitle = conseil?.datasConseil?.lien?.title;
  const conseilLienUrl = conseil?.datasConseil?.lien?.url;

  const conseilHasFichier = conseil?.datasConseil?.fichier;
  const conseilFichierUrl = conseil?.datasConseil?.fichier?.node?.mediaItemUrl;

  const programmesSynch = conseil?.lienProgrammeConseils?.lienProgramme;
  const avancementUser = conseil?.viewer?.userDatas?.avancement;

  const handleProgrammeClick = (id) => navigate(`/entrainements/programme/${id}`);

  // Fonction pour calculer le pourcentage de progression
  const calculateProgression = (totalSeances, completedSeances) => {
    if (totalSeances === 0) return 0;
    return (completedSeances / totalSeances) * 100;
  };

  useEffect(() => {
    if (conseil && !conseilPublished) {
      navigate('/accueil');
    }
  }, [conseilPublished]);

  useEffect(() => {
    if (isidParamsFavorite) {
      setFavoriteButton(true);
    }
  }, [isidParamsFavorite]);

  const handleFavorite = async () => {
    const formattedId = databaseIdConseil.toString();
    if (postLoadingFavorite) return;
    setFavoriteButton(!favoriteButton);
    try {
      const response = await postFavorite({
        variables: {
          id: idUser,
          updateFavoris: formattedId,
        },
      });      
    } catch (err) {
      setFavoriteButton(!favoriteButton);
      setErrorMessageFavorite('Erreur lors du like');
      console.log(err);
    }
  };

  useEffect(() => {
    setErrorMessageFavorite(null);
  }, [favoriteButton, handleFavorite]);


  // Si le conseil n'est pas accessible, redirige vers /conseils
  useEffect(() => {
    if (!isAccessible) {
      navigate("/conseils");
    }
  }, [isAccessible, navigate]);

  // Empêche le rendu si la redirection est en cours
  if (!isAccessible) {
    return null;
  }

  if (loadingConseil)
    return (
      <div className='w-full loadingScreen'>
        <div className='loading'>
          <FontAwesomeIcon className='animate-spin' icon='fa-duotone fa-spinner-third' />
        </div>
      </div>
    );

  return (
    <div className='w-full relative'>

      <Helmet>
        <title>{conseilTitle} | Conseils</title>
      </Helmet>

      <div className='topBloc overlay content'>
        <div className='leftSide'>
          <div className='return'>
            <FontAwesomeIcon
              icon='fa-solid fa-chevron-left'
              onClick={() => {
                navigate(-1);
              }}
            />
          </div>
        </div>

        <div className='rightSide'>
          <div className={`IconFavoris ${favoriteButton ? 'active' : ''}`} onClick={handleFavorite}>
            {favoriteButton ? (
              <FontAwesomeIcon icon='fa-solid fa-heart' />
            ) : (
              <FontAwesomeIcon icon='fa-regular fa-heart' />
            )}
          </div>
          <div
            className='IconProfil lg:hidden'
            onClick={() => {
              navigate('/profil');
            }}
          >
            <FontAwesomeIcon icon='fa-solid fa-user' />
          </div>
        </div>
      </div>

      <div className='blurredPattern skin detail'></div>

      {/* image header */}
      <div className='topThumbConseil Desktop'>
        {conseilThumbnailDesktopSrc ? (
          <img
            src={conseilThumbnailDesktopSrc}
            srcSet={conseilThumbnailDesktopSrcSet}
            alt={conseilThumbnailDesktopAlt}
          />
        ) : (
          <img src={DefaultImage} alt='Thumbnail par défaut' />
        )}
      </div>

      <div className='topThumbConseil Mobile'>
        {conseilThumbnailMobileSrc ? (
          <img
            src={conseilThumbnailMobileSrc}
            srcSet={conseilThumbnailMobileSrcSet}
            alt={conseilThumbnailMobileAlt}
          />
        ) : (
          <img src={DefaultImage} alt='Thumbnail par défaut' />
        )}
      </div>

      <div className='content contentConseil'>
        {/* titre du conseil */}
        <h1>{conseilTitle}</h1>

        {errorMessageFavorite && <p className='alert'>{errorMessageFavorite}</p>}

        {/* contenu du conseil */}
        <div
          className='blockTextRendered'
          dangerouslySetInnerHTML={{ __html: conseilContent }}
        ></div>

        {/* Lien du conseil */}
        {conseilHasLien && (
          <a href={conseilLienUrl} target='_blank' rel='noreferrer' className='btn Lien'>
            {conseilLienTitle}
          </a>
        )}

        {/* Fichier du conseil */}
        {conseilHasFichier && (
          <a href={conseilFichierUrl} target='_blank' rel='noreferrer' className='btn Fichier'>
            Télécharger
          </a>
        )}
      </div>

      {programmesSynch && (
        <div className='content contentProgrammes'>
          <h2 className='titleProgrammeBlock'>Découvrez nos programmes</h2>
          <div className='gridProgrammes'>
            {programmesSynch?.nodes?.map((programme, index) => {
              const nbSeances = programme?.seances?.planificationProgramme?.reduce(
                (acc, planification) =>
                  acc +
                  (planification?.synchProgrammesSeances?.nodes?.filter(
                    (seance) => viewerIsAdmin || seance.status === "publish"
                  ).length || 0),
                0
              );

              const filteredData = avancementUser?.filter(
                (item) => item.programmeId === programme?.databaseId.toString()
              );
              const completedSeances = filteredData?.reduce(
                (total, item) => total + item.seances?.length,
                0
              );
              const avancementPerCent = Math.round(
                calculateProgression(nbSeances, completedSeances)
              );

              const isProgrammeAccessible = viewerIsFreemium ? programme?.datasProgrammes?.enableFreemiumAccount : true;

              return (
                <ProgrammesCompo
                  onClick={() => handleProgrammeClick(programme?.id)}
                  key={index}
                  niveau={programme?.niveaux?.edges?.[0]?.node?.name}
                  niveauSlug={programme?.niveaux?.edges?.[0]?.node?.slug}
                  label={programme?.title}
                  nbSeance={nbSeances}
                  src={programme?.thumbnailsProgramme?.thumbnailProgrammePaysage?.node?.sourceUrl}
                  srcSet={programme?.thumbnailsProgramme?.thumbnailProgrammePaysage?.node?.srcSet}
                  alt={programme?.thumbnailsProgramme?.thumbnailProgrammePaysage?.node?.altText}
                  id={programme?.id}
                  avancement={avancementPerCent}
                  freemiumAccess={isProgrammeAccessible}
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}
